import { Box } from '@/components/box';
import { Prose } from '@/components/prose';
import { Blockquote } from '@/components/serializers/blockquote';
import {
  blockWithoutContainers,
  typesWithoutContainers,
} from '@/lib/sanity/portable-text';
import { styled } from '@/stitches.config';
import { TextAndQuote as TTextAndQuote } from '@/types/sanity';
import { PortableText } from '@portabletext/react';

type TextAndQuoteProps = Omit<TTextAndQuote, '_type' | 'quoteAlignment'> & {
  quoteAlignment: 'left' | 'right';
};

export function TextAndQuote(props: TextAndQuoteProps) {
  const { text, quote, quoteAlignment } = props;

  const quoteComponent = (
    <Blockquote quotee={quote.quotee} source={quote.source}>
      {quote.quote}
    </Blockquote>
  );

  const textComponent = text ? (
    <Prose>
      <PortableText
        value={text}
        components={{
          block: blockWithoutContainers,
          types: typesWithoutContainers,
        }}
      />
    </Prose>
  ) : null;

  const leftComponent =
    quoteAlignment === 'left' ? quoteComponent : textComponent;

  const rightComponent =
    quoteAlignment === 'right' ? quoteComponent : textComponent;

  return (
    <Container>
      <Column alignment={quoteAlignment}>
        <Box
          css={{
            marginBottom: '$9',
            '@bp4': {
              marginBottom: 'initial',
            },
          }}
        >
          {leftComponent}
        </Box>
      </Column>
      <Column alignment={quoteAlignment}>{rightComponent}</Column>
    </Container>
  );
}

const Container = styled('div', {
  '@bp2': {
    display: 'contents',
    gap: '$4',
  },
});

const Column = styled('div', {
  variants: {
    alignment: {
      left: {
        '@bp2': {
          gridColumn: 'main',
        },
        '@bp4': {
          '&:nth-child(1)': {
            gridColumn: 'main-start / span 7',
          },
          '&:nth-child(2)': {
            gridColumn: '11 / span 6',
          },
        },
      },
      right: {
        '@bp2': {
          gridColumn: 'main',
          '&:nth-child(2)': {
            order: 1,
          },
          '&:nth-child(1)': {
            order: 2,
          },
        },

        '@bp4': {
          '&:nth-child(1)': {
            gridColumn: 'main-start / span 6',
          },
          '&:nth-child(2)': {
            gridColumn: '10 / span 7',
          },
        },
      },
    },
  },
});
