import {
  blockWithoutContainers,
  typesWithoutContainers,
} from '@/lib/sanity/portable-text';
import { styled } from '@/stitches.config';
import { Column as TColumn, Columns as TColumns } from '@/types/sanity';
import { PortableText } from '@portabletext/react';
import { SanityKeyed } from 'sanity-codegen';
import { Prose } from '../prose';

type ColumnProps = {
  column: SanityKeyed<TColumn>;
};

export type ColumnsProps = {
  data: SanityKeyed<TColumns>;
};

function Column({ column }: ColumnProps) {
  const { content, width, isSticky } = column;

  return (
    <StyledColumn width={width as 1 | 2}>
      <Sticky sticky={isSticky}>
        <Prose>
          {content && (
            <PortableText
              value={content}
              key={column._key}
              components={{
                block: blockWithoutContainers,
                types: typesWithoutContainers,
              }}
            />
          )}
        </Prose>
      </Sticky>
    </StyledColumn>
  );
}

export function Columns({ data }: ColumnsProps) {
  // console.warn(
  //   'Using a legacy Columns component. Pleace replace with either 3-columns-text or introduction-text.'
  // );
  return (
    <Container>
      {data?.columns &&
        data?.columns.map((column) => (
          <Column column={column} key={column._key} />
        ))}
    </Container>
  );
}

const Container = styled('div', {
  display: 'grid',
  gridRowGap: '$6',

  '@bp3': {
    display: 'contents',
  },
});

const Sticky = styled('div', {
  variants: {
    sticky: {
      true: {
        position: 'sticky',
        top: '$9',
      },
    },
  },
});

const StyledColumn = styled('div', {
  '*:first-child:not(:only-child)': {
    mt: 0,
  },

  '*:last-child': {
    mb: 0,
  },

  '*:only-child': {
    mb: 0,
  },

  // Add an extra breakpoint where just use 2 columns
  '@bp3': {
    '&:nth-child(odd)': {
      gridColumn: '3 / span 7',
    },

    '&:nth-child(even)': {
      gridColumn: '10 / span 7',
    },
  },

  // When we have more whitespace we'll switch to 3 columns
  '@bp7': {
    '&:nth-child(1n)': {
      gridColumn: 'main-start / span 5',
    },

    '&:nth-child(2n)': {
      gridColumn: '8 / span 5',
    },

    '&:nth-child(3n)': {
      gridColumn: '13 / span 4',
    },
  },

  variants: {
    sticky: {
      true: {
        position: 'sticky',
        top: '100px',
      },
    },
    width: {
      1: {},
      2: {
        '&:nth-child(1n)': {
          gridColumn: 'main-start / span 10',
        },
      },
    },
  },
});
