import { ButtonLink } from '@/components/button-link';
import { AwardsTableProps } from '@/lib/sanity/portable-text';
import { styled } from '@/stitches.config';
import { AwardsRow as TAwardsRow, SanityKeyed } from '@/types/sanity';
import { useMemo, useState } from 'react';

// If we're ever gonna use a project at the last column,
// we should revisit how it looks and behaves on mobile
const ENABLE_PROJECT_COLUMN = false;

export function AwardsTable({ rows, minimumVisibleRows }: AwardsTableProps) {
  const [showAll, setShowAll] = useState(false);

  const awardsByYear:
    | [year: string, awards: SanityKeyed<TAwardsRow>[]][]
    | undefined = useMemo(() => {
    if (!rows) return;

    const renderedRows = showAll ? rows : rows.slice(0, minimumVisibleRows);

    const years = [...new Set(renderedRows.map((el) => el.year))];
    return years.map((year) => [
      year,
      renderedRows.filter((row) => row.year === year),
    ]);
  }, [minimumVisibleRows, rows, showAll]);

  if (!awardsByYear || !rows) return null;

  const hasProject = rows.some((row) => 'project' in row);

  return (
    <Container>
      <Table>
        <thead>
          <TableRow>
            <TableHead>
              <span className="visually-hidden">Year</span>
            </TableHead>
            <TableHead>Organisations</TableHead>
            <TableHead>Distinction</TableHead>
            {ENABLE_PROJECT_COLUMN && hasProject && (
              <TableHead>Project</TableHead>
            )}
          </TableRow>
        </thead>
        {/*
         * see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/tbody#multiple_bodies
         * to get an idea of why this is valid
         */}
        {awardsByYear.map(([year, awards]) => (
          <tbody key={year}>
            {awards.map((award, awardIndex) => (
              <TableRow key={award._key}>
                {awardIndex === 0 && (
                  <Cell
                    as={'th'}
                    rowSpan={awards.length}
                    css={{ fontWeight: '$heavy' }}
                  >
                    {award.year}
                  </Cell>
                )}
                <Cell>{award.organisation}</Cell>
                <Cell>{award.distinction}</Cell>
                {ENABLE_PROJECT_COLUMN && hasProject && (
                  <Cell>{award.project}</Cell>
                )}
              </TableRow>
            ))}
          </tbody>
        ))}
      </Table>

      {minimumVisibleRows < rows.length && (
        <ButtonContainer>
          <ButtonLink onClick={() => setShowAll((current) => !current)}>
            <span>{showAll ? 'Show less' : 'Show more'}</span>
          </ButtonLink>
        </ButtonContainer>
      )}
    </Container>
  );
}

const Container = styled('div', {
  gridColumn: 'wide !important',
});

const Table = styled('table', {
  width: '100%',
  borderSpacing: 0,

  '@bp3': {
    tableLayout: 'fixed',
  },
});

const TableRow = styled('tr', {
  width: '100%',
});

const TableHead = styled('th', {
  position: 'relative',
  textAlign: 'left',
  padding: 0,
  fontSize: '$2',
  fontWeight: '$heavy',
  paddingBottom: '$3',

  '&:after': {
    content: "''",
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: 2,
    backgroundColor: '$foreground',
    zIndex: 9,
  },
});

const Cell = styled('td', {
  position: 'relative',
  padding: '$3 0',
  fontSize: '$2',
  fontWeight: '$normal',
  textAlign: 'left',
  verticalAlign: 'top',

  // Year column
  '&:first-of-type': {
    paddingRight: '$6',

    '@bp3': {
      paddingRight: 'unset',
    },
  },

  // Organisation column
  '&:nth-child(2)': {
    paddingRight: '$2',

    '@bp3': {
      paddingRight: 'unset',
    },
  },

  '&:before': {
    content: "''",
    position: 'absolute',
    top: -1,
    left: 0,
    width: '100%',
    height: 1,
    backgroundColor: '$gray8',
  },

  '&:after': {
    content: "''",
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: 1,
    backgroundColor: '$gray8',
  },
});

const ButtonContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  mt: '$8',
});
