import { Contact as ContactType } from '@/types/sanity';
import { urlForImage } from '@/lib/sanity/sanity';
import { ContactBadge } from './contact-badge';
import { Box } from './box';
import { Title } from './contact/styled-text';

export function Contact(props: ContactType) {
  return (
    <Box css={{ py: '$6' }}>
      <Title css={{ color: '$foreground', mb: '$7' }}>{props.title}</Title>
      <ContactBadge
        imageSrc={urlForImage(props.image.asset).url()}
        name={props.name}
        email={props.email}
        phoneNumber={props.phoneNumber}
        size="lg"
      />
    </Box>
  );
}
